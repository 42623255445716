<div class="m-subheader">
  <div class="mr-auto">
    <h3 class="m-subheader__title">
      {{ 'sales.statistics' | transloco }}
    </h3>

    <hx-date-range-select class="date-align" [(ngModel)]="params.date" (selectChange)="applyFilters()"></hx-date-range-select>

    <button (click)="print()"
            type="button"
            class="btn btn-light m--margin-right-10 date-align">
        <span>
          <i class="la la-print"></i>
          <span>{{ 'sales.btn.print' | transloco }}</span>
        </span>
    </button>
  </div>
</div>

<div class="m-content" id="contentToPrint">
  <div class="row">
    <div class="col-sm-12">
      <div class="m-portlet m-portlet--full-height">
        <div class="m-portlet__body">
          <div class="wrap m--margin-10">
            <div class="row today-info">
              <div class="col today-cm">{{ 'sales.today' | transloco }}, <p> {{ now | date:'dd MMMM' }}</div>
              <div class="col today-cm">{{ salesCurrentDay?.total ?? 0 | moneyFormat | uiCurrency:salesCurrentDay?.currency }} <p class="col-text"> {{ 'sales.revenue' | transloco }}</div>
              <div class="col today-cm">{{ (salesCurrentDay?.total - salesCurrentDay?.totalCostPrice) ?? 0 | fixed:2  | moneyFormat | uiCurrency:salesCurrentDay?.currency }} <p class="col-text"> {{ 'sales.profit' | transloco }}</div>
              <div class="col today-cm">{{ salesCurrentDay?.orderCount ?? 0 }}<p class="col-text"> {{ 'sales.receipts' | transloco }}</div>
              <div class="col today-cm">{{ salesCurrentDay?.orderWithoutClientCount + salesCurrentDay?.orderWithClientCount }}<p class="col-text"> {{ 'sales.visitors' | transloco }}</div>
              <div class="col today-cm">{{ salesCurrentDay?.averageReceipt ?? 0  | moneyFormat | uiCurrency:salesCurrentDay?.currency }} <p class="col-text"> {{ 'sales.avgReceipt' | transloco }}</div>
            </div>
          </div>
          <div class="m--margin-top-50 stat-info-header">
            {{ 'sales.' + statView  | transloco }}

            <button (click)="changeRangeView('day')" [ngClass]="{ 'stat-btn-clicked': rangeType === 'day' }"
                    type="button"
                    class="stat-btn-left">
              <span>{{ 'sales.btn.day' | transloco }}</span>
            </button>
            <button (click)="changeRangeView('week')" [ngClass]="{ 'stat-btn-clicked': rangeType === 'week' }"
                    type="button"
                    class="stat-btn">
              <span>{{ 'sales.btn.week' | transloco }}</span>
            </button>
            <button (click)="changeRangeView('month')" [ngClass]="{ 'stat-btn-clicked': rangeType === 'month' }"
                    type="button"
                    class="stat-btn-right">
              <span>{{ 'sales.btn.month' | transloco }}</span>
            </button>
          </div>
          <div>
            <div [hidden]="!sales.length" id="chartdiv" class="chartdiv"></div>
            <div [hidden]="sales.length" class="chartdiv no-data">{{ 'sales.noData' | transloco }}</div>
          </div>
          <div class="m--margin-top-50 wrap m--margin-10">
            <div class="row stat-info">
              <div class="col today-cm link-style" (click)="changeGraphInfo('revenue')" [ngClass]="{ 'stat-chosen' : statView === 'revenue' }"><i class="las la-chart-line m--margin-right-5 icon-graph-color"></i>{{ this.revenueTotal | moneyFormat | uiCurrency:salesCurrentDay?.currency }} <p class="col-text"> {{ 'sales.revenue' | transloco }}</div>
              <div class="col today-cm link-style" (click)="changeGraphInfo('profit')" [ngClass]="{ 'stat-chosen' : statView === 'profit' }"><i class="las la-chart-line m--margin-right-5 icon-graph-color"></i>{{ this.profitTotal | fixed:2 | moneyFormat | uiCurrency:salesCurrentDay?.currency }} <p class="col-text"> {{ 'sales.profit' | transloco }}</div>
              <div class="col today-cm link-style" (click)="changeGraphInfo('receipts')" [ngClass]="{ 'stat-chosen' : statView === 'receipts' }"><i class="las la-chart-line m--margin-right-5 icon-graph-color"></i>{{ this.receiptsTotal | moneyFormat | uiCurrency:salesCurrentDay?.currency }} <p class="col-text"> {{ 'sales.receipts' | transloco }}</div>
              <div class="col today-cm link-style" (click)="changeGraphInfo('visitors')" [ngClass]="{ 'stat-chosen' : statView === 'visitors' }"><i class="las la-chart-line m--margin-right-5 icon-graph-color"></i>{{ this.visitorsTotal | moneyFormat | uiCurrency:salesCurrentDay?.currency }}<p class="col-text"> {{ 'sales.visitors' | transloco }}</div>
              <div class="col today-cm link-style" (click)="changeGraphInfo('avgReceipt')" [ngClass]="{ 'stat-chosen' : statView === 'avgReceipt' }"><i class="las la-chart-line m--margin-right-5 icon-graph-color"></i>{{ this.avgReceiptTotal | moneyFormat | uiCurrency:salesCurrentDay?.currency }}<p class="col-text"> {{ 'sales.avgReceipt' | transloco }}</div>
            </div>
          </div>

          <div class="grid-container m--margin-top-20">
            <div class="grid-item m--margin-right-20">
              <div class="m--margin-top-20 m--margin-left-15">
                <span class="title">{{ 'sales.payment-types' | transloco }}</span>
                <span class="float-right m--margin-right-20 link-style" [ngClass]="{'link-clicked' : paymentTypeClicked}" (click)="receipts()">{{ 'sales.btn.receipts' | transloco }}</span>
                <span class="float-right m--margin-right-10 link-style" [ngClass]="{'link-clicked' : !paymentTypeClicked}" (click)="revenue()">{{ 'sales.btn.turnover' | transloco }}</span>
              </div>
              <div [hidden]="!paymentTypes" id="paymentTypeChart" class="chartdiv500"></div>
              <div [hidden]="paymentTypes" class="chartdiv500 no-data">{{ 'sales.noData' | transloco }}</div>
            </div>

            <div class="grid-item">
              <div class="m--margin-top-20 m--margin-left-15">
                <span class="title">{{ 'sales.source-type' | transloco }}</span>
                <span class="float-right m--margin-right-20 link-style" [ngClass]="{'link-clicked' : sourceTypeClicked}" (click)="receiptsST()">{{ 'sales.btn.receipts' | transloco }}</span>
                <span class="float-right m--margin-right-10 link-style" [ngClass]="{'link-clicked' : !sourceTypeClicked}" (click)="revenueST()">{{ 'sales.btn.turnover' | transloco }}</span>
              </div>
              <div [hidden]="!sourceTypes" id="sourceTypeChart" class="chartdiv500"></div>
              <div [hidden]="sourceTypes" class="chartdiv500 no-data">{{ 'sales.noData' | transloco }}</div>
            </div>
          </div>

          <div class="container-gr">
            <div class="left-side">
              <div class="m--margin-top-20 m--margin-left-15">
                <span class="title">{{ 'sales.by-time' | transloco }}</span>
              </div>
              <div [hidden]="!ordersTime.length" id="chartByTyme" class="chartdiv"></div>
              <div [hidden]="ordersTime.length" class="chartdiv no-data">{{ 'sales.noData' | transloco }}</div>
            </div>
            <div class="right-side">
              <div class="m--margin-top-20 m--margin-left-15">
                <span class="title">{{ 'sales.day-of-week' | transloco }}</span>
              </div>
              <div [hidden]="!ordersDayOfWeek.length" id="chartByWeek" class="chartdiv"></div>
              <div [hidden]="ordersDayOfWeek.length" class="chartdiv no-data">{{ 'sales.noData' | transloco }}</div>
            </div>
          </div>
          <div class="product-info">
            <div class="m--margin-top-20 m--margin-left-15">
              <span class="title">{{ 'sales.popular-products' | transloco }}</span>
            </div>
            <div *ngIf="!leftPopularProducts.length && !rightPopularProducts.length" class="no-data">{{ 'sales.noData' | transloco }}</div>
            <div *ngIf="leftPopularProducts.length || rightPopularProducts.length" class="container-product-main">
              <div class="left-side-product">
                <div class="container-product">
                  <div class="left-und font-weight-bold">{{ 'sales.product' | transloco }}</div>
                  <div class="right-und font-weight-bold">{{ 'sales.orders' | transloco }}</div>
                </div>
                <div class="container-product" *ngFor="let pp of leftPopularProducts">
                  <div class="left-und blue-txt">{{ pp.productInfoTitle | uiLabel }}</div>
                  <div class="right-und">{{ pp.amountSum }} {{ 'sales.piece-short' | transloco }}</div>
                </div>
                <div class="m--margin-bottom-20"></div>
              </div>
              <div class="right-side-product">
                <div class="container-product">
                  <div class="left-und font-weight-bold">{{ 'sales.product' | transloco }}</div>
                  <div class="right-und font-weight-bold">{{ 'sales.orders' | transloco }}</div>
                </div>
                <div class="container-product" *ngFor="let pp of rightPopularProducts">
                  <div class="left-und blue-txt">{{ pp.productInfoTitle | uiLabel }}</div>
                  <div class="right-und">{{ pp.amountSum }} {{ 'sales.piece-short' | transloco }}</div>
                </div>
                <div class="m--margin-bottom-20"></div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  </div>
</div>
