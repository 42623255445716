import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injectable, Injector, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe, registerLocaleData } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import localeRuKZ from '@angular/common/locales/ru-KZ';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { CancelOrderComponent, OrderStatsComponent, OrderViewComponent, } from './order';
import { UserOrderComponent } from './order/user-order/user-order.component';
import { InvoiceListComponent } from './pages/invoice-list/invoice-list.component';
import { OrderStatDetailComponent } from '@manager-app/order';
import { KeycloakAngularModule } from 'keycloak-angular';
import { UserPermissionComponent } from './pages/user/user-permission/user-permission.component';
import * as Sentry from '@sentry/browser';
import { environment } from '@manager-env/environment';
import { InvoiceEventsComponent } from './components/invoice-events/invoice-events.component';
import { DeliveryListComponent } from './pages/delivery/delivery-list/delivery-list.component';
import { DeliveryNewComponent } from './pages/delivery/delivery-new/delivery-new.component';
import { UserListComponent } from './pages/user/user-list/user-list.component';
import { ComponentModule } from './components/component.module';
import { AppRoutingModule } from './app-routing.module';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DashboardNewComponent } from './pages/dashboard/dashboard-new/dashboard-new.component';
import { BalanceViewComponent } from './components/balance-view/balance-view.component';
import {
  ApiModule,
  ComponentType,
  Configuration,
  flattenObject,
  HX_APP_VERSION,
  HX_COMPONENT_NAME,
  HX_ENV_MODE,
  HX_GOOGLE_MAPS_API_KEY,
  HX_KEYCLOAK,
  HxError,
  TranslocoRootModule,
  UiError
} from 'hx-services';
import { BalanceNewComponent } from './modal/balance-new/balance-new.component';
import { DeliveryEditModalComponent } from '@manager-app/modal/delivery-edit-modal/delivery-edit-modal.component';
import { InvoiceModalComponent } from '@manager-app/modal/invoice/invoice.modal';
import { BalanceChangerModalComponent } from '@manager-app/modal/balance-changer/balance-changer.modal';
import { StoreProductComponent } from '@manager-app/components/store-product/store-product.component';
import { InvoiceProductComponent } from '@manager-app/components/invoice-product/invoice-product.component';
import { ProductDiscardListComponent } from '@manager-app/pages/product/discard/product-discard-list.component';
import { PayInvoiceModal } from '@manager-app/modal/pay-invoice/pay-invoice.modal'; // a plugin
import { ProductCategoryPipe } from '@manager-app/utils/product-category.pipe';
import { SettingsComponent } from '@manager-app/pages/settings/settings.component';
import { UserEditComponent } from '@manager-app/pages/user/user-edit/user-edit.component';
import { WorkHistoryComponent } from '@manager-app/pages/work-history/work-history.component';
import { APP_VERSION } from '@manager-env/version';
import { HttpErrorResponse } from '@angular/common/http';
import { ContextMenuModule } from '@perfectmemory/ngx-contextmenu';
import { StoreWorkTimeModal } from '@manager-app/modal/store-work-time/store-work-time.modal';
import { TranslocoService } from '@ngneat/transloco';
import { TimesheetListComponent } from '@manager-app/pages/timesheet/list/timesheet-list.component';
import { TimesheetEditComponent } from '@manager-app/pages/timesheet/edit/timesheet-edit.component';
import { UserContractComponent } from '@manager-app/pages/user/user-contract/user-contract.component';
import { StoreInfoComponent } from '@manager-app/pages/store-info/store-info.component';
import { StoreEncashmentListComponent } from '@manager-app/pages/store-encashment/list/store-encashment-list.component';
import { StoreEncashmentEditComponent } from '@manager-app/pages/store-encashment/edit/store-encashment-edit.component';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DeliveryHeatCountMapComponent } from '@manager-app/reports/delivery-heat-count-map/delivery-heat-count-map.component';
import { RoyaltyComponent } from '@manager-app/pages/royalty/royalty.component';
import { SalesComponent } from '@manager-app/pages/sales/sales.component';

if (environment.sentryUrl) {
  Sentry.init({
    release: environment.version,
    environment: environment.production ? 'production' : 'staging',
    ignoreErrors: ['Unauthorized', 'productBalance.zero'],
    dsn: environment.sentryUrl,
    beforeSend: (event, hint) => {
      console.log('[sentry] before send', event, hint);
      if (hint && hint.originalException && hint.originalException instanceof HttpErrorResponse) {
        if (hint.originalException.status === 401) {
          console.log('[sentry] skipped error logging', event);
          return null;
        }
      }
      return event;
    },
    integrations: [
      new Sentry.Integrations.TryCatch({
        XMLHttpRequest: false,
      }),
    ],
  });
}

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {
  }

  handleError(error: any) {
    console.log('err', error);
    if (error.promise && error.rejection) {
      console.error('[got-rejection] %O', error);
      error = error.rejection;
    }
    const toastr = this.injector.get(ToastrService);
    const tr = this.injector.get(TranslocoService);
    if (error instanceof HttpErrorResponse) {
      const ex: HxError = error.error?.message ? error.error as HxError : {
        code: 577,
        id: '-',
        message: error.message,
        data: error.error ?? {},
      };
      toastr.error(ex.description ?? tr.translate(ex.message, flattenObject(ex.data)), tr.translate('error') + ' ' + ex.id);
    }
    if (error instanceof UiError) {
      toastr.error(tr.translate(error.message, error.data ?? {}));
    } else {
      console.error('[error-handler] %O', error);
    }
    Sentry.captureException(error.originalError || error);
  }
}

// the second parameter 'fr' is optional
registerLocaleData(localeRuKZ);

function getKeycloakUrl() {
  if (environment.production) {
    return location.origin.replace(location.origin.split('//')[1].split('.')[0], 'auth') + '/auth';
  }
  return environment.keycloakUrl;
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    OrderViewComponent,
    OrderStatsComponent,
    CancelOrderComponent,
    UserOrderComponent,
    InvoiceListComponent,
    OrderStatDetailComponent,
    UserListComponent,
    DeliveryListComponent,
    DeliveryNewComponent,
    SettingsComponent,
    BalanceChangerModalComponent,
    InvoiceEventsComponent,
    UserPermissionComponent,
    DashboardComponent,
    DashboardNewComponent,
    BalanceViewComponent,
    StoreProductComponent,
    StoreProductComponent,
    InvoiceModalComponent,
    BalanceNewComponent,
    DeliveryEditModalComponent,
    InvoiceProductComponent,
    ProductDiscardListComponent,
    WorkHistoryComponent,
    PayInvoiceModal,
    ProductCategoryPipe,
    UserEditComponent,
    StoreWorkTimeModal,
    TimesheetListComponent,
    TimesheetEditComponent,
    UserContractComponent,
    StoreInfoComponent,
    StoreEncashmentListComponent,
    StoreEncashmentEditComponent,
    DeliveryHeatCountMapComponent,
    RoyaltyComponent,
    SalesComponent,
  ],
  imports: [
    BrowserModule,
    FullCalendarModule,
    SharedModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    TranslocoRootModule,
    ComponentModule,
    NgxMaskPipe,
    NgxMaskDirective,
    KeycloakAngularModule,
    ContextMenuModule,
    ApiModule.forRoot(() => new Configuration({basePath: ''})),
  ],
  providers: [
    DatePipe,
    provideEnvironmentNgxMask(),
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    },
    {
      provide: HX_COMPONENT_NAME,
      useValue: ComponentType.manager
    },
    {
      provide: HX_APP_VERSION,
      useValue: APP_VERSION
    },
    {
      provide: HX_KEYCLOAK,
      useValue: {
        url: getKeycloakUrl(),
        clientId: environment.keycloakClientId,
        realm: environment.keycloakRealm
      }
    },
    {
      provide: HX_GOOGLE_MAPS_API_KEY,
      useValue: environment.googleMapsApiKey
    },
    {provide: HX_ENV_MODE, useValue: environment.production},
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
