<button type="button" class="hc-button hc-button--success" (click)="createDashboard()">
  {{ 'dashboard.create.button' | transloco }}
</button>

<ng-template #templateRef let-modal>
  <div class="modal-header modal-header--brand">
    <h3 class="modal-title">{{ 'dashboard.modal.title' | transloco }}: {{store.title | uiLabel}}</h3>

    <button class="close" type="button" (click)="modal.dismiss()">
      <span>×</span>
    </button>
  </div>

  <div class="modal-body m--padding-top-10">
    <hx-loader *ngIf="productID" [length]="productInfoList.length" [isLoading]="isLoading.product"></hx-loader>

    <div class="row">
      <div class="col-md-6">
        <hx-product-info-select *ngIf="!isCreateMode" [types]="searchProductInfoTypes"
                                [(ngModel)]="selectedProductInfoId" [storeId]="store.id"
                                (selectChange)="onProductInfoChanged($event)"></hx-product-info-select>
      </div>
      <div class="col-md-6 text-right">
        <span *ngIf="(addBtnShow && isEditMode) || isCreateMode" class="m--margin-right-10">
          <button type="button" class="hc-button hc-button--dashboard" (click)="addNewProductGroup(true)" [ngbTooltip]="'dashboard.create.group.start' | transloco">
            {{ 'dashboard.create.group' | transloco }}
          </button>
        </span>

        <span *ngIf="!isCreateMode" class="m--margin-right-10">
          <button type="button" class="hc-button hc-button-cancel--dashboard" (click)="reset()" [ngbTooltip]="'reset.list' | transloco">
            {{ 'reset.list' | transloco }}
          </button>
        </span>

        <button *ngIf="!isEditMode && !isCreateMode" type="button" class="hc-button hc-button--dashboard" (click)="edit()">
          {{ 'dashboard.setEditMode' | transloco }}
        </button>
      </div>
    </div>

    <form *ngIf="!isLoading.product" class="m-form m-form--state m-form--label-align-right" [formGroup]="dashboardForm" (ngSubmit)="onSubmit()">
      <div class="m--margin-bottom-10">
        <ng-container *ngIf="isEditMode || isCreateMode">
          <div class="row">
            <div class="col-md-5">
              <label class="hc-label">
                {{ 'dashboard.select.dates' | transloco }}:
              </label>

              <hx-date-range-select formControlName="dateRange"></hx-date-range-select>
            </div>
          </div>
          <div class="row hc-group m--margin-0 m--margin-bottom-10 m--margin-top-10">
            <div class="col-md-3">
              <ng-container formGroupName="data" class="m--margin-left-10">
                <label for="decored" formGroupName="decor" class="hc-checkbox">
                  <input formControlName="enabled" type="checkbox" name="decored" id="decored">
                  <span>{{ 'dashboard.decor' | transloco }}</span>
                </label>
              </ng-container>
            </div>
            <div class="col-md-4">
              <ng-container formGroupName="data">
                <label for="onlineSellable" formGroupName="onlineSellable" class="hc-checkbox">
                  <input formControlName="enabled" type="checkbox" name="onlineSellable" id="onlineSellable">
                  <span>{{ 'dashboard.online.sale' | transloco }}</span>
                </label>
              </ng-container>
            </div>
          </div>
          <div class="row hc-group m--margin-0">
            <div class="col-md-3">
              <ng-container formGroupName="data" class="m--margin-left-10">
                <label for="inscription" formGroupName="inscription" class="hc-checkbox" (click)="changeInscription()">
                  <input formControlName="enabled" type="checkbox" name="inscription" id="inscription">
                  <span>{{ 'dashboard.inscription' | transloco }}</span>
                </label>
              </ng-container>
            </div>

            <div class="col-md-8">
              <div>
                <ng-container *ngIf="inscriptionEnabled" formGroupName="data">
                  <container formGroupName="inscription">
                    {{ 'dashboard.select.inscription.add' | transloco }}:
                    <input type="text" formControlName="from" class="time-range-input form-control m-input m--margin-right-5" [placeholder]="'decor-time-modal.timeOpen' | transloco" [dropSpecialCharacters]="false" [mask]="'00:00'">
                    <input type="text" formControlName="to" class="time-range-input form-control m-input" [placeholder]="'decor-time-modal.timeClose' | transloco" [dropSpecialCharacters]="false" [mask]="'00:00'">
                  </container>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="isEditMode || isCreateMode">
        <ng-container *ngIf="isProductFind || addProductGroup">
          <div class="hc-group" *ngFor="let even = even;let i = index; let productItem of productItems">
            <button *ngIf="productItem.reserve === 0" class="hc-group__close" type="button" [ngbTooltip]="'dashboard.limit.delete' | transloco" (click)="removeNewGroup(i)">
              <span>×</span>
            </button>

            <div class="row">
              <div class="col-md-7">
                <div class="row m--margin-bottom-15">
                  <div class="col-md-7">
                    <label class="hc-label">
                      {{ 'dashboard.select.product' | transloco }}:
                    </label>

                    <hx-product-info-select [types]="searchProductInfoTypes"
                                            (selectChange)="onProductInfoSelected($event, productItem)"
                                            [storeId]="store.id"
                                            [clearAfterSelect]="true"></hx-product-info-select>
                  </div>
                </div>

                <div class="hc-tagList">
                  <div class="hc-tagList__item" *ngFor="let item of productItem.productList; let itemIndex = index">
                    <button class="hc-tagList__remove" (click)="removeProduct(item)" [ngbTooltip]="'dashboard.delete' | transloco">
                      <i class="la la-remove"></i>
                    </button>

                    <span>{{ item.title | uiLabel }}</span>
                  </div>
                </div>
              </div>

              <div class="col-md-5">
                <div class="hc-numbers">
                  <div class="hc-numbers__item">
                    <div class="hc-numbers__line">
                      <span (click)="increasePlan(productItem)" class="hc-numbers__btn">
                        <i class="la la-angle-up"></i>
                      </span>

                      <input type="number" [ngStyle]="getWidth(productItem.plan)" [ngModelOptions]="{standalone: true}"
                             [(ngModel)]="productItem.plan" class="hc-numbers__input" [ngClass]="{ 'hc-margin-bottom': !checkFreeSale(productItem)}">

                      <span *ngIf="checkFreeSale(productItem)" (click)="decreasePlan(productItem)" class="hc-numbers__btn">
                        <i class="la la-angle-down"></i>
                      </span>
                    </div>

                    <span class="hc-numbers__title">{{ 'dashboard.plan' | transloco }}</span>
                  </div>

                  <div class="hc-numbers__item">
                    <div class="hc-numbers__line">
                      <span (click)="increaseLimit(productItem)" class="hc-numbers__btn">
                        <i class="la la-angle-up"></i>
                      </span>

                      <input type="number" [ngStyle]="getWidth(productItem.limit)" [ngModelOptions]="{standalone: true}"
                             [(ngModel)]="productItem.limit" class="hc-numbers__input" [ngClass]="{ 'hc-margin-bottom': !checkFreeSale(productItem)}">

                      <span *ngIf="checkFreeSale(productItem)" (click)="decreaseLimit(productItem)" class="hc-numbers__btn">
                        <i class="la la-angle-down"></i>
                      </span>
                    </div>

                    <span class="hc-numbers__title">{{ 'dashboard.limit' | transloco }}</span>
                  </div>


                  <div class="hc-numbers__item">
                    <div class="hc-numbers__line m--margin-top-20 m--margin-bottom-20">
                      <input type="number" disabled="true" [ngStyle]="getWidth(productItem.limit)" [ngModelOptions]="{standalone: true}"
                             [(ngModel)]="productItem.reserve" class="hc-numbers__input">
                    </div>

                    <span class="hc-numbers__title">{{ 'dashboard.reserve' | transloco }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="!isProductFind && !isCreateMode">
          <div class="hc-hide-group" [ngClass]="{'hc-background': !category.enabled}" *ngFor="let even = even;let i = index; let category of categories">
            <div class="row align-items-center" [ngClass]="{'hc-margin-bottom': category.enabled}" (click)="expandCategory(category)">
              <div class="col-md-7 hc-display-flex">
                <i *ngIf="!category.enabled" class="las la-angle-down m--padding-right-5 m--margin-top-5"></i>
                <i *ngIf="category.enabled" class="las la-angle-up m--padding-right-5 m--margin-top-5"></i>
                <h5 class="modal-title">{{ category.title | uiLabel }}</h5>
              </div>
            </div>
            <div *ngIf="category.enabled">
              <div class="hc-group" *ngFor="let even = even;let k = index; let productItem of productInfoList | filterProductCategory:category.id">
                <button *ngIf="productItem.reserve === 0" class="hc-group__close" type="button" (click)="removeGroup(productItem)">
                  <span>×</span>
                </button>

                <div class="row">
                  <div class="col-md-7">
                    <div class="row m--margin-bottom-15">
                      <div class="col-md-7">
                        <label class="hc-label">
                          {{ 'dashboard.select.product' | transloco }}:
                        </label>

                        <hx-product-info-select [types]="searchProductInfoTypes"
                                                (selectChange)="onProductInfoSelected($event, productItem)"
                                                [clearAfterSelect]="true" [storeId]="store.id"></hx-product-info-select>
                      </div>
                    </div>

                    <div class="hc-tagList">
                      <div class="hc-tagList__item" *ngFor="let item of productItem.productList; let itemIndex = index">
                        <button class="hc-tagList__remove" (click)="removeProduct(item)" [ngbTooltip]="'dashboard.delete' | transloco">
                          <i class="la la-remove"></i>
                        </button>

                        <span>{{ item.title | uiLabel }}</span>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-5">
                    <div class="hc-numbers">
                      <div class="hc-numbers__item">
                        <div class="hc-numbers__line">
                          <span (click)="increasePlan(productItem)" class="hc-numbers__btn">
                            <i class="la la-angle-up"></i>
                          </span>

                          <input type="number" [ngStyle]="getWidth(productItem.plan)" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="productItem.plan" class="hc-numbers__input" [ngClass]="{ 'hc-margin-bottom': !checkFreeSale(productItem)}">

                          <span *ngIf="checkFreeSale(productItem)" (click)="decreasePlan(productItem)" class="hc-numbers__btn">
                            <i class="la la-angle-down"></i>
                          </span>
                        </div>

                        <span class="hc-numbers__title">{{ 'dashboard.plan' | transloco }}</span>
                      </div>

                      <div class="hc-numbers__item">
                        <div class="hc-numbers__line">
                          <span (click)="increaseLimit(productItem)" class="hc-numbers__btn">
                              <i class="la la-angle-up"></i>
                            </span>

                          <input type="number" [ngStyle]="getWidth(productItem.limit)" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="productItem.limit" class="hc-numbers__input" [ngClass]="{ 'hc-margin-bottom': !checkFreeSale(productItem)}">

                          <span *ngIf="checkFreeSale(productItem)" (click)="decreaseLimit(productItem)" class="hc-numbers__btn">
                              <i class="la la-angle-down"></i>
                            </span>
                        </div>

                        <span class="hc-numbers__title">{{ 'dashboard.limit' | transloco }}</span>
                      </div>


                      <div class="hc-numbers__item">
                        <div class="hc-numbers__line m--margin-top-20 m--margin-bottom-20">
                          <input type="number" disabled="disabled" [ngStyle]="getWidth(productItem.limit)" [ngModelOptions]="{standalone: true}"
                                 [(ngModel)]="productItem.reserve" class="hc-numbers__input">
                        </div>

                        <span class="hc-numbers__title">{{ 'dashboard.reserve' | transloco }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>


      <ng-container *ngIf="!isEditMode && !isCreateMode && isProductFind">
        <div *ngFor="let even = even;let i = index; let productItem of productInfoShowList">
          <div class="row align-items-center">
            <div class="col-md-7 hc-display-flex">
              <i class="las la-angle-up m--padding-right-5 m--margin-top-5"></i>
              <h5 class="modal-title">{{ this.categoryMap.get(productItem.categories[0].id).title | uiLabel }}</h5>
            </div>
            <div class="col-md-5">
              <div class="hc-numbers hc-numbers--line">
                <div class="hc-view-numb" [ngbTooltip]="'dashboard.plan' | transloco">
                  {{ 'dashboard.plan' | transloco }}
                </div>

                <div class="hc-view-numb" [ngbTooltip]="'dashboard.limit' | transloco">
                  {{ 'dashboard.limit' | transloco }}
                </div>
              </div>
            </div>
          </div>
          <hr class="m--margin-top-5 m--margin-bottom-5">
          <div class="row align-items-center hc-margin-left-2">
            <div class="col-md-7">
              <div class="hc-tagList--view">
                <div *ngFor="let item of productItem.productList">
                  <span style="display: block">{{ item.title | uiLabel }}</span>
                </div>
                <hx-loader [isLoading]="isLoading.productList" [length]="productItem.productList?.length"></hx-loader>
              </div>
            </div>

            <div class="col-md-5">
              <div class="hc-numbers hc-numbers--line">
                <div class="hc-view-numb" [ngbTooltip]="'dashboard.plan' | transloco">
                  {{ productItem.plan }}
                </div>

                <div class="hc-view-numb" [ngbTooltip]="'dashboard.limit' | transloco">
                  {{ productItem.limit}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!isEditMode && !isCreateMode && !isProductFind">
        <div class="hc-hide-group" [ngClass]="{'hc-background': !category.enabled}" *ngFor="let even = even;let i = index; let category of categories">
          <div class="row align-items-center" [ngClass]="{'hc-margin-bottom': category.enabled}" (click)="expandCategory(category)">
            <div class="col-md-7 hc-display-flex">
              <i *ngIf="!category.enabled" class="las la-angle-down m--padding-right-5 m--margin-top-5"></i>
              <i *ngIf="category.enabled" class="las la-angle-up m--padding-right-5 m--margin-top-5"></i>
              <h5 class="modal-title">{{ category.title | uiLabel }}</h5>
            </div>
            <div class="col-md-5" *ngIf="category.enabled">
              <div class="hc-numbers hc-numbers--line">
                <div class="hc-view-numb" [ngbTooltip]="'dashboard.plan' | transloco">
                  {{ 'dashboard.plan' | transloco }}
                </div>

                <div class="hc-view-numb" [ngbTooltip]="'dashboard.limit' | transloco">
                  {{ 'dashboard.limit' | transloco }}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="category.enabled">
            <div *ngFor="let even = even;let i = index; let productItem of productInfoShowList | filterProductCategory:category.id">
              <hr class="m--margin-top-5 m--margin-bottom-5">
              <div class="row align-items-center hc-margin-left-2">
                <div class="col-md-7">
                  <div class="hc-tagList--view">
                    <div *ngFor="let item of productItem.productList">
                      <span>{{ item.title | uiLabel }}</span><br>
                    </div>

                    <hx-loader [isLoading]="isLoading.productList" [length]="productItem.productList?.length"></hx-loader>
                  </div>
                </div>

                <div class="col-md-5">
                  <div class="hc-numbers hc-numbers--line">
                    <div class="hc-view-numb" [ngbTooltip]="'dashboard.plan' | transloco">
                      {{ productItem.plan }}
                    </div>

                    <div class="hc-view-numb" [ngbTooltip]="'dashboard.limit' | transloco">
                      {{ productItem.limit}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="modal-footer modal-footer--brand">
        <div class="col-md-6">
          <button *ngIf="(addBtnShow && isEditMode) || isCreateMode" type="button" class="hc-button hc-button--secondary" (click)="addNewProductGroup()">
            {{ 'dashboard.create.group' | transloco }}
          </button>
        </div>
        <div class="col-md-6 m--align-right">
          <button *ngIf="isEditMode || isCreateMode" type="submit" class="hc-button hc-button--success m--align-right">
            {{ 'button.save' | transloco }}
          </button>
        </div>
      </div>
    </form>

  </div>
</ng-template>

<ng-template #confirmRef let-modal>
  <div class="modal-header modal-header--brand">
    <h3 class="modal-title">{{ 'dashboard.modal.title' | transloco }}</h3>
  </div>

  <div class="modal-body">
    <p>{{ 'dashboard.limit.already.exist' | transloco }}</p>
  </div>

  <div class="modal-footer">
    <div class="col-lg-6">
      <button [disabled]="isLoading.save" type="button" class="hc-button hc-button--secondary" (click)="confirmProperty('APPLY_ALL', modal)">
        {{ 'button.applyAll' | transloco }}
      </button>

      <button [disabled]="isLoading.save" type="button" class="hc-button hc-button--success" (click)="confirmProperty('APPLY_ALL_EXCEPT_EXIST', modal)">
        {{ 'button.applyExcept' | transloco }}
      </button>
    </div>

    <div class="col-lg-6 m--align-right">
      <button type="button" (click)="closeModal(modal)" class="btn btn-secondary">
        {{ 'btn.cancel' | transloco }}
      </button>
    </div>
  </div>
</ng-template>
