import { HttpClient, HttpParams } from '@angular/common/http';
import { provideTransloco, Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, TranslocoConfig, translocoConfig, TranslocoLoader, TranslocoModule } from '@ngneat/transloco';
import { inject, Inject, Injectable, isDevMode, NgModule } from '@angular/core';
import { HX_APP_VERSION, HX_COMPONENT_NAME, HX_ENV_MODE, isoDate } from './interface';
import { ComponentType } from './vn-api';

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(
    private http: HttpClient,
    @Inject(HX_COMPONENT_NAME) private componentName: string,
    @Inject(HX_APP_VERSION) private appVersion: string,
    ) {
  }

  getTranslation(lang: string) {
    console.log('lang=', lang);
    return this.http.get<Translation>(`assets/i18n/${lang}.json`, {params: new HttpParams().set('app_version', this.appVersion ?? isoDate(Date.now()))});
  }
}

export function getTranslocoConfig(isProdMode: boolean): TranslocoConfig {
  return translocoConfig({
    availableLangs: ['en', 'ru', 'kk'],
    defaultLang: 'en',
    // Remove this option if your application
    // doesn't support changing language in runtime.
    fallbackLang: 'en',
    reRenderOnLangChange: true,
    prodMode: isProdMode,
  });
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: ['en', 'ru', 'kk'],
        defaultLang: ['us', 'pro'].includes(location.hostname.split('.')[location.hostname.split('.').length - 1]) ? 'en': 'en' ,
        // Remove this option if your application
        // doesn't support changing language in runtime.
        //fallbackLang: 'en',
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader
    }),
  ]
})
export class TranslocoRootModule {
}
