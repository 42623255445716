import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import {
  DateRange, HxReportService, StatisticsSalesModel, extractDateRange, isoDate, StatisticsPaymentTypesModel, StatisticsOrdersDayOfWeekModel,
  StatisticsOrdersTimeModel,
  StatisticsPopularProductsModel,
  StatisticsSourceTypesModel, PaymentType, toRouterQueryParams, toFixed
} from 'hx-services';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorHandlerService } from '@manager-app/service/error-handler.service';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { firstValueFrom, Subscription } from 'rxjs';
import { XYChart } from '@amcharts/amcharts5/xy';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

type ViewType = 'revenue' | 'profit' | 'receipts' | 'visitors' | 'avgReceipt';
type RangeType = 'day' | 'week' | 'month';

interface TempArrayModel {
  date: string,
  value: number
}

interface OrdersDayOfWeekModel extends StatisticsOrdersDayOfWeekModel {
  dayOfWeekStr?: string;
}

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})
export class SalesComponent implements OnInit, OnDestroy {
  params: {
    date?: DateRange,
  }= { date: { from: isoDate(new Date().setMonth(new Date().getMonth() - 1)), to: isoDate(new Date()) } };
  statView: ViewType = 'revenue';
  rangeType: RangeType = 'day';
  sales: StatisticsSalesModel[] = [];
  paymentTypes: StatisticsPaymentTypesModel;
  sourceTypes: StatisticsSourceTypesModel;
  leftPopularProducts: StatisticsPopularProductsModel[] = [];
  rightPopularProducts: StatisticsPopularProductsModel[] = [];
  ordersTime: StatisticsOrdersTimeModel[] = [];
  ordersDayOfWeek: OrdersDayOfWeekModel[] = [];
  salesCurrentDay: StatisticsSalesModel;
  isloading = {
    sales: false,
    paymentTypes: false,
    sourceTypes: false,
    popularProducts: false,
    ordersTime: false,
    ordersDayOfWeek: false,
  };
  showPaymentType = false;
  data: { date: string, value: number }[] = [];
  now = isoDate(new Date());
  dataPaymentTypes: {paymentType: PaymentType, total: number}[] = [];
  dataSourceTypes: {sourceType: string, total: number}[] = [];
  paymentTypeClicked = false;
  sourceTypeClicked = false;
  revenueTotal: number = 0;
  profitTotal: number = 0;
  receiptsTotal: number = 0;
  visitorsTotal: number = 0;
  avgReceiptTotal: number = 0;
  dayOfWeekMap: Map<number, string> = new Map<number, string>();
  storeIds: number[] = [];
  private sub!: Subscription;
  private chartPaymentType: XYChart;
  private chartSourceType: XYChart;
  private chartSale: XYChart;
  private chartOrdersTime: XYChart;
  private chartOrdersDayOfWeek: XYChart;

  constructor(
    private reportService: HxReportService,
    private aRoute: ActivatedRoute,
    private router: Router,
    private errorHandlerService: ErrorHandlerService,
    private toastr: ToastrService,
    private zone: NgZone,
    private tr:TranslocoService,
  ) {
  }

  browserOnly(fn: () => void) {
    this.zone.runOutsideAngular(() => fn());
  }

  async ngOnInit() {
    await firstValueFrom(this.tr.selectTranslateObject('demo'));
    this.initDayOfWeekMap();
    this.sub = this.aRoute.queryParamMap.subscribe(async paramMap => {
      this.params.date = extractDateRange('date', paramMap) ?? { from: isoDate(new Date().setMonth(new Date().getMonth() - 1)), to: isoDate(new Date()) };
      await Promise.all([
        this.loadSales(),
        this.loadCurrentDaySales(),
        this.loadPaymentTypes(),
        this.loadSourceTypes(),
        this.loadPopularProducts(),
        this.loadOrdersTime(),
        this.loadOrdersDayOfWeek(),
      ]);
      if (!this.chartSale) {
        this.createChart();
      }
      this.loadChart();
      this.fillPaymentType();
      this.fillSourceType();
      this.fillOrdersTime();
      this.fillOrdersDayOfWeek();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.disposeCharts();
  }

  applyFilters() {
    this.navigate();
  }

  print() {
    const element = document.getElementById('contentToPrint') as HTMLElement;

    html2canvas(element).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4'
      });

      const imgWidth = 210;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

      const pdfBlob = pdf.output('blob');
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl);
    });
  }

  changeGraphInfo(viewType: ViewType) {
    this.statView = viewType;
    this.rangeType = 'day';
    this.loadChart();
  }

  async changeRangeView(rangeType: RangeType) {
    this.rangeType = rangeType;
    this.loadChart();
  }

  receipts() {
    this.paymentTypeClicked = !this.paymentTypeClicked;
    this.receiptPaymentType();
    this.fillPaymentType();
  }

  revenue(){
    this.paymentTypeClicked = !this.paymentTypeClicked;
    this.revenuePaymentType();
    this.fillPaymentType();
  }

  receiptsST() {
    this.sourceTypeClicked = !this.sourceTypeClicked;
    this.receiptSourceType();
    this.fillSourceType();
  }

  revenueST(){
    this.sourceTypeClicked = !this.sourceTypeClicked;
    this.revenueSourceType();
    this.fillSourceType();
  }

  private navigate() {
    this.router.navigate(['./'], {
      queryParams: toRouterQueryParams(this.params),
      relativeTo: this.aRoute
    });
  }

  private async createChart() {
    Promise.all([
      import('@amcharts/amcharts5/index'),
      import('@amcharts/amcharts5/xy'),
      import('@amcharts/amcharts5/themes/Animated').then(res => res.default),
      import('@amcharts/amcharts5/locales/ru_RU').then(res => res.default),
    ]).then(([am5, am5xy, am5themes_Animated, am5ru]) => {
      //Payment Type chart
      {
        const root = am5.Root.new('paymentTypeChart');
        root.setThemes([
          am5themes_Animated.new(root)
        ]);

        const chart = root.container.children.push(am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
          paddingLeft: 0,
          layout: root.verticalLayout
        }));

        const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
          categoryField: 'paymentType',
          renderer: am5xy.AxisRendererY.new(root, {
            inversed: true,
            minGridDistance: 20
          })
        }));
        yAxis.data.clear();
        const data = this.dataPaymentTypes;
        yAxis.data.setAll(data);

        const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: -10,
            minorGridEnabled: true,
          }),
        }));

        const yRendered = yAxis.get('renderer');
        yRendered.grid.template.set('visible', false);

        const xRendered = xAxis.get('renderer');
        xRendered.labels.template.set('visible', false);
        xRendered.grid.template.set('visible', false);

        const series = chart.series.push(am5xy.ColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: 'total',
          categoryYField: 'paymentType',
          sequencedInterpolation: true,
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: '[bold]{categoryY}: {valueX}'
          })
        }));

        series.columns.template.setAll({
          height: am5.percent(30),
          dy: -5,
          width: am5.percent(70),
        });

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationX: 1,
            sprite: am5.Label.new(root, {
              centerY: am5.p50,
              text: '{valueX}',
              populateText: true,
            })
          });
        });
        series.data.clear();
        series.data.setAll(data);
        series.appear();

        const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
          behavior: 'zoomY'
        }));
        cursor.lineY.set('forceHidden', true);
        cursor.lineX.set('forceHidden', true);
        chart.appear(1000, 100);
        this.chartPaymentType = chart;
      }
      //Source Type chart
      {
        const root = am5.Root.new('sourceTypeChart');
        root.setThemes([
          am5themes_Animated.new(root)
        ]);

        const chart = root.container.children.push(am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
          paddingLeft: 0,
          layout: root.verticalLayout
        }));

        const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
          categoryField: 'sourceType',
          renderer: am5xy.AxisRendererY.new(root, {
            inversed: true,
          })
        }));
        yAxis.data.clear();
        const data = this.dataSourceTypes;
        yAxis.data.setAll(data);

        const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {}),
        }));

        const yRendered = yAxis.get('renderer');
        yRendered.grid.template.set('visible', false);

        const xRendered = xAxis.get('renderer');
        xRendered.labels.template.set('visible', false);
        xRendered.grid.template.set('visible', false);

        const series = chart.series.push(am5xy.ColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: 'total',
          categoryYField: 'sourceType',
          sequencedInterpolation: true,
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: '[bold]{categoryY}: {valueX}'
          })
        }));

        series.columns.template.setAll({
          height: am5.percent(30),
          dy: -5,
          width: am5.percent(70),
        });

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationX: 1,
            sprite: am5.Label.new(root, {
              centerY: am5.p50,
              text: '{valueX}',
              populateText: true,
            })
          });
        });
        series.data.clear();
        series.data.setAll(data);
        series.appear();

        const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
          behavior: 'zoomY'
        }));
        cursor.lineY.set('forceHidden', true);
        cursor.lineX.set('forceHidden', true);
        chart.appear(1000, 100);
        this.chartSourceType = chart;
      }
      //Chart by Week
      {
        const root = am5.Root.new('chartByWeek');

        root.setThemes([
          am5themes_Animated.new(root)
        ]);

        const chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: 'panX',
            wheelY: 'zoomX',
            pinchZoomX: true
          })
        );

        const xAxis = chart.xAxes.push(
          am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: 'dayOfWeekStr',
            renderer: am5xy.AxisRendererX.new(root, {
              minGridDistance: 30
            }),
            tooltip: am5.Tooltip.new(root, {})
          })
        );

        const yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
          })
        );

        const series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'totalSum',
            sequencedInterpolation: true,
            categoryXField: 'dayOfWeekStr',
            tooltip: am5.Tooltip.new(root, {
              labelText: '{valueY}'
            })
          })
        );

        this.ordersDayOfWeek.sort((a, b) => a.dayOfWeek - b.dayOfWeek);
        const data = this.ordersDayOfWeek;

        xAxis.data.setAll(data);
        series.data.setAll(data);

        chart.set('cursor', am5xy.XYCursor.new(root, {}));
        this.chartOrdersDayOfWeek = chart;
      }
      //Chart by time
      {
        const root = am5.Root.new('chartByTyme');

        root.setThemes([
          am5themes_Animated.new(root)
        ]);

        const chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: 'panX',
            wheelY: 'zoomX',
            pinchZoomX: true
          })
        );

        const xAxis = chart.xAxes.push(
          am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: 'hour',
            renderer: am5xy.AxisRendererX.new(root, {
              minGridDistance: 30
            }),
            tooltip: am5.Tooltip.new(root, {})
          })
        );

        const yAxis = chart.yAxes.push(
          am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {})
          })
        );

        const series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: 'totalSum',
            sequencedInterpolation: true,
            categoryXField: 'hour',
            tooltip: am5.Tooltip.new(root, {
              labelText: '{valueY}'
            })
          })
        );
        const data = this.ordersTime;

        xAxis.data.setAll(data);
        series.data.setAll(data);

        chart.set('cursor', am5xy.XYCursor.new(root, {}));
        this.chartOrdersTime = chart;
      }
      //Chart Sales
      {
        const root = am5.Root.new('chartdiv');
        root.locale = am5ru;
        root.setThemes([
          am5themes_Animated.new(root)
        ]);
        root.dateFormatter.setAll({
          dateFormat: 'yyyy',
          dateFields: ['valueX'],
        });

        const chart = root.container.children.push(am5xy.XYChart.new(root, {
          focusable: true,
          panX: true,
          panY: true,
          wheelX: 'panX',
          wheelY: 'zoomX',
          pinchZoomX: true,
          paddingLeft: 0,
        }));
        const easing = am5.ease.linear;

        const xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
          maxDeviation: 0.1,
          groupData: false,
          baseInterval: {
            timeUnit: 'day',
            count: 1
          },
          renderer: am5xy.AxisRendererX.new(root, {
            minorGridEnabled: true,
            minGridDistance: 70
          }),
          tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.get('renderer').labels.template.setAll({
          rotation: -90,
          centerY: am5.p50,
          centerX: am5.p50,
          paddingTop: 10,
          fontSize: 10,
        });

        xAxis.set('dateFormatter', am5.DateFormatter.new(root, {
          dateFormat: 'yyyy-MM-dd' // Customize the format here
        }));

        const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
          maxDeviation: 0.2,
          renderer: am5xy.AxisRendererY.new(root, {})
        }));

        const series = chart.series.push(am5xy.LineSeries.new(root, {
          minBulletDistance: 7,
          connect: true,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: '{valueY}'
          })
        }));

        series.fills.template.setAll({
          fillOpacity: 0.2,
          visible: true
        });

        series.strokes.template.setAll({
          strokeWidth: 2
        });

        series.data.processor = am5.DataProcessor.new(root, {
          dateFormat: 'yyyy-MM-dd',
          dateFields: ['date']
        });

        series.data.setAll(this.data);

        series.bullets.push(function() {
          const circle = am5.Circle.new(root, {
            radius: 4,
            fill: root.interfaceColors.get('background'),
            stroke: series.get('fill'),
            strokeWidth: 2
          });

          return am5.Bullet.new(root, {
            sprite: circle
          });
        });

        const cursor = chart.set('cursor', am5xy.XYCursor.new(root, {
          xAxis: xAxis,
          behavior: 'none'
        }));
        cursor.lineY.set('visible', false);
        chart.appear(1000, 100);
        this.chartSale = chart;

        this.changeRangeView('day');
      }
    }).catch((e) => {
      console.error('Error when creating chart', e);
    });
  }

  private async loadSales() {
    this.sales = [];
    this.isloading.sales = true;
    try {
      this.sales = await this.reportService.getStatisticsSales({fromDate: this.params.date.from, toDate: this.params.date.to, storeIds: this.storeIds });
      this.revenueTotal = this.sales.reduce((a, b) => a + b.total, 0);
      this.profitTotal = this.sales.reduce((a, b) => a + b.totalCostPrice, 0);
      this.receiptsTotal = this.sales.reduce((a, b) => a + b.orderCount, 0);
      this.visitorsTotal = this.sales.reduce((a, b) => a + (b.orderWithClientCount + b.orderWithoutClientCount), 0);
      this.avgReceiptTotal = toFixed(this.revenueTotal / this.receiptsTotal);
    } finally {
      this.isloading.sales = false;
    }
  }

  private async loadCurrentDaySales() {
    this.isloading.sales = true;
    try {
      const sales = await this.reportService.getStatisticsSales({fromDate: isoDate(new Date()), toDate: isoDate(new Date()), storeIds: this.storeIds });
      if (sales.length > 0) {
        this.salesCurrentDay = sales[0];
      } else {
        this.salesCurrentDay = undefined;
      }
    } finally {
      this.isloading.sales = false;
    }
  }

  private async loadPaymentTypes() {
    this.isloading.paymentTypes = true;
    try {
      const pt = await this.reportService.getStatisticsPaymentTypes({fromDate: this.params.date.from, toDate: this.params.date.to, storeIds: this.storeIds });
      if (pt.length > 0) {
        this.paymentTypes = pt[0];
        this.revenuePaymentType();
      } else {
        this.paymentTypes = undefined;
      }
    } finally {
      this.isloading.paymentTypes = false;
    }
  }

  private async loadSourceTypes() {
    this.isloading.sourceTypes = true;
    try {
      const st = await this.reportService.getStatisticsSourceTypes({fromDate: this.params.date.from, toDate: this.params.date.to, storeIds: this.storeIds });
      if (st.length > 0) {
        this.sourceTypes = st[0];
        this.revenueSourceType();
      } else {
        this.sourceTypes = undefined;
      }
    } finally {
      this.isloading.sourceTypes = false;
    }
  }


  private async loadPopularProducts() {
    this.leftPopularProducts = [];
    this.rightPopularProducts = [];
    this.isloading.popularProducts = true;
    try {
      const popularProducts = await this.reportService.getStatisticsPopularProducts({fromDate: this.params.date.from, toDate: this.params.date.to, storeIds: this.storeIds });
      if (popularProducts.length > 0) {
        popularProducts.sort((a, b) => b.amountSum - a.amountSum);
        this.leftPopularProducts = popularProducts.slice(0, 4);
        this.rightPopularProducts = popularProducts.slice(4, 8);
      }
    } finally {
      this.isloading.popularProducts = false;
    }
  }

  private async loadOrdersTime() {
    this.ordersTime = [];
    this.isloading.ordersTime = true;
    try {
      this.ordersTime = await this.reportService.getStatisticsOrdersTime({fromDate: this.params.date.from, toDate: this.params.date.to, storeIds: this.storeIds });
      this.ordersTime.sort((a, b) => a.hour - b.hour);
    } finally {
      this.isloading.ordersTime = false;
    }
  }

  private async loadOrdersDayOfWeek() {
    this.ordersDayOfWeek = [];
    this.isloading.ordersDayOfWeek = true;
    try {
      this.ordersDayOfWeek = await this.reportService.getStatisticsOrdersDayOfWeek({fromDate: this.params.date.from, toDate: this.params.date.to, storeIds: this.storeIds });
      this.ordersDayOfWeek.sort((a, b) => a.dayOfWeek - b.dayOfWeek);
      this.ordersDayOfWeek.forEach(r => r.dayOfWeekStr = this.dayOfWeekMap.get(r.dayOfWeek));
    } finally {
      this.isloading.ordersDayOfWeek = false;
    }
  }

  private loadChart() {
    this.data = [];
    if (this.rangeType === 'day') {
      this.fillData();
      this.chartSale?.series.getIndex(0).data.clear();
      this.chartSale?.series.getIndex(0).data.setAll(this.data);
    } else if (this.rangeType === 'week') {
      this.fillData();
      const weeklySummaries = this.summarizeTotalsByWeek(this.data);
      this.chartSale?.series.getIndex(0).data.clear();
      this.chartSale?.series.getIndex(0).data.setAll(weeklySummaries);
    } else if (this.rangeType === 'month') {
      this.fillData();
      const monthlySummaries = this.summarizeByFirstDateOfMonth(this.data);
      this.chartSale?.series.getIndex(0).data.clear();
      this.chartSale?.series.getIndex(0).data.setAll(monthlySummaries);
    }
  }

  private fillData() {
    if (this.statView === 'revenue') {
      this.sales.forEach(r => {
        this.data.push({ date: r.paymentDate, value: r.total });
      });
    } else if (this.statView === 'profit') {
      this.sales.forEach(r => {
        this.data.push({ date: r.paymentDate, value: r.totalCostPrice });
      });
    } else if (this.statView === 'receipts') {
      this.sales.forEach(r => {
        this.data.push({ date: r.paymentDate, value: r.orderCount });
      });
    } else if (this.statView === 'visitors') {
      this.sales.forEach(r => {
        this.data.push({ date: r.paymentDate, value: r.orderWithoutClientCount + r.orderWithClientCount });
      });
    } else if (this.statView === 'avgReceipt') {
      this.sales.forEach(r => {
        this.data.push({ date: r.paymentDate, value: r.averageReceipt });
      });
    }
  }

  private revenuePaymentType() {
    this.dataPaymentTypes = [];
    if ((this.paymentTypes.kaspiSum ?? 0) > 0) {
      this.dataPaymentTypes.push({ paymentType: this.tr.translate('paymentType.KASPI'), total: this.paymentTypes.kaspiSum ?? 0 });
    }
    if ((this.paymentTypes.posSum ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.POS'), total: this.paymentTypes.posSum ?? 0});
    }
    if ((this.paymentTypes.forteSum ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.FORTE'), total: this.paymentTypes.forteSum ?? 0});
    }
    if ((this.paymentTypes.cashSum ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.CASH'), total: this.paymentTypes.cashSum ?? 0});
    }
    if ((this.paymentTypes.checkingAccountSum ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.CHECKING_ACCOUNT'), total: this.paymentTypes.checkingAccountSum ?? 0});
    }
    if ((this.paymentTypes.payboxSum ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.PAYBOX'), total: this.paymentTypes.payboxSum ?? 0});
    }
    if ((this.paymentTypes.squareappSum ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.SQUAREAPP'), total: this.paymentTypes.squareappSum ?? 0});
    }
    if ((this.paymentTypes.kaspiPosSum ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.KASPI_POS'), total: this.paymentTypes.kaspiPosSum ?? 0});
    }
    if ((this.paymentTypes.coinSum ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.COIN'), total: this.paymentTypes.coinSum ?? 0});
    }
    this.dataPaymentTypes.sort((a, b) => b.total - a.total);
  }

  private receiptPaymentType() {
    this.dataPaymentTypes = [];
    if ((this.paymentTypes.kaspiCount ?? 0) > 0) {
      this.dataPaymentTypes.push({ paymentType: this.tr.translate('paymentType.KASPI'), total: this.paymentTypes.kaspiCount ?? 0 });
    }
    if ((this.paymentTypes.posCount ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.POS'), total: this.paymentTypes.posCount ?? 0});
    }
    if ((this.paymentTypes.forteCount ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.FORTE'), total: this.paymentTypes.forteCount ?? 0});
    }
    if ((this.paymentTypes.cashCount ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.CASH'), total: this.paymentTypes.cashCount ?? 0});
    }
    if ((this.paymentTypes.checkingAccountCount ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.CHECKING_ACCOUNT'), total: this.paymentTypes.checkingAccountCount ?? 0});
    }
    if ((this.paymentTypes.payboxCount ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.PAYBOX'), total: this.paymentTypes.payboxCount ?? 0});
    }
    if ((this.paymentTypes.squareappCount ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.SQUAREAPP'), total: this.paymentTypes.squareappCount ?? 0});
    }
    if ((this.paymentTypes.kaspiPosCount ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.KASPI_POS'), total: this.paymentTypes.kaspiPosCount ?? 0});
    }
    if ((this.paymentTypes.coinCount ?? 0) > 0) {
      this.dataPaymentTypes.push({paymentType: this.tr.translate('paymentType.COIN'), total: this.paymentTypes.coinCount ?? 0});
    }
    this.dataPaymentTypes.sort((a, b) => b.total - a.total);
  }

  private fillPaymentType() {
    this.chartPaymentType?.series.getIndex(0).data.clear();
    this.chartPaymentType?.series.getIndex(0).data.setAll(this.dataPaymentTypes);
  }

  private receiptSourceType() {
    this.dataSourceTypes = [];
    if ((this.sourceTypes.callCenterTotalCount ?? 0) > 0) {
      this.dataSourceTypes.push({ sourceType: this.tr.translate('sourceSystem.CALLCENTER'), total: this.sourceTypes.callCenterTotalCount ?? 0 });
    }
    if ((this.sourceTypes.cashboxTotalCount ?? 0) > 0) {
      this.dataSourceTypes.push({sourceType: this.tr.translate('sourceSystem.CASHBOX'), total: this.sourceTypes.cashboxTotalCount ?? 0});
    }
    if ((this.sourceTypes.instagramTotalCount ?? 0) > 0) {
      this.dataSourceTypes.push({sourceType: this.tr.translate('sourceSystem.INSTAGRAM'), total: this.sourceTypes.instagramTotalCount ?? 0});
    }
    if ((this.sourceTypes.managerTotalCount ?? 0) > 0) {
      this.dataSourceTypes.push({sourceType: this.tr.translate('sourceSystem.MANAGER'), total: this.sourceTypes.managerTotalCount ?? 0});
    }
    if ((this.sourceTypes.onlineTotalCount ?? 0) > 0) {
      this.dataSourceTypes.push({sourceType: this.tr.translate('sourceSystem.ONLINE'), total: this.sourceTypes.onlineTotalCount ?? 0});
    }
    if ((this.sourceTypes.onlineAppTotalCount ?? 0) > 0) {
      this.dataSourceTypes.push({sourceType: this.tr.translate('sourceSystem.ONLINE_APP'), total: this.sourceTypes.onlineAppTotalCount ?? 0});
    }
    if ((this.sourceTypes.telegramTotalCount ?? 0) > 0) {
      this.dataSourceTypes.push({sourceType: this.tr.translate('sourceSystem.TELEGRAM'), total: this.sourceTypes.telegramTotalCount ?? 0});
    }
    if ((this.sourceTypes.whatsappTotalCount ?? 0) > 0) {
      this.dataSourceTypes.push({sourceType: this.tr.translate('sourceSystem.WHATSAPP'), total: this.sourceTypes.whatsappTotalCount ?? 0});
    }
    this.dataSourceTypes.sort((a, b) => b.total - a.total);
  }

  private revenueSourceType() {
    this.dataSourceTypes = [];
    if ((this.sourceTypes.callCenterTotalSum ?? 0) > 0) {
      this.dataSourceTypes.push({ sourceType: this.tr.translate('sourceSystem.CALLCENTER'), total: this.sourceTypes.callCenterTotalSum ?? 0 });
    }
    if ((this.sourceTypes.cashboxTotalSum ?? 0) > 0) {
      this.dataSourceTypes.push({sourceType: this.tr.translate('sourceSystem.CASHBOX'), total: this.sourceTypes.cashboxTotalSum ?? 0});
    }
    if ((this.sourceTypes.instagramTotalSum ?? 0) > 0) {
      this.dataSourceTypes.push({sourceType: this.tr.translate('sourceSystem.INSTAGRAM'), total: this.sourceTypes.instagramTotalSum ?? 0});
    }
    if ((this.sourceTypes.managerTotalSum ?? 0) > 0) {
      this.dataSourceTypes.push({sourceType: this.tr.translate('sourceSystem.MANAGER'), total: this.sourceTypes.managerTotalSum ?? 0});
    }
    if ((this.sourceTypes.onlineTotalSum ?? 0) > 0) {
      this.dataSourceTypes.push({sourceType: this.tr.translate('sourceSystem.ONLINE'), total: this.sourceTypes.onlineTotalSum ?? 0});
    }
    if ((this.sourceTypes.onlineAppTotalSum ?? 0) > 0) {
      this.dataSourceTypes.push({sourceType: this.tr.translate('sourceSystem.ONLINE_APP'), total: this.sourceTypes.onlineAppTotalSum ?? 0});
    }
    if ((this.sourceTypes.telegramTotalSum ?? 0) > 0) {
      this.dataSourceTypes.push({sourceType: this.tr.translate('sourceSystem.TELEGRAM'), total: this.sourceTypes.telegramTotalSum ?? 0});
    }
    if ((this.sourceTypes.whatsappTotalSum ?? 0) > 0) {
      this.dataSourceTypes.push({sourceType: this.tr.translate('sourceSystem.WHATSAPP'), total: this.sourceTypes.whatsappTotalSum ?? 0});
    }
    this.dataSourceTypes.sort((a, b) => b.total - a.total);
  }

  private fillSourceType() {
    this.chartSourceType?.series.getIndex(0).data.clear();
    this.chartSourceType?.series.getIndex(0).data.setAll(this.dataSourceTypes);
  }

  private groupDaysByWeeks(days: TempArrayModel[]): TempArrayModel[][] {
    const weeks: TempArrayModel[][] = [];
    let currentWeek: TempArrayModel[] = [];

    days.forEach(day => {
      const date = day.date;
      if (new Date(date).getDay() === 0 && currentWeek.length > 0) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
      currentWeek.push(day);
    });
    if (currentWeek.length > 0) {
      weeks.push(currentWeek);
    }
    return weeks;
  }

  private summarizeTotalsByWeek(days: TempArrayModel[]): TempArrayModel[] {
    const weeks = this.groupDaysByWeeks(days);
    return weeks.map(week => {
      const date = week[0].date;
      const value = week.reduce((sum, day) => sum + day.value, 0);
      return { date, value };
    });
  }

  private summarizeByFirstDateOfMonth(data: TempArrayModel[]): TempArrayModel[] {
    const monthlyTotals: { [key: string]: number } = {};
    data.forEach(entry => {
      const firstDateOfMonth = new Date(new Date(entry.date).getFullYear(), new Date(entry.date).getMonth() + 1, 1);
      const key = firstDateOfMonth.toISOString().slice(0, 7); // Format: 'YYYY-MM'

      if (!monthlyTotals[key]) {
        monthlyTotals[key] = 0;
      }
      monthlyTotals[key] += entry.value;
    });
    return Object.keys(monthlyTotals).map(key => ({
      date: isoDate(new Date(key + '-01')),
      value: monthlyTotals[key],
    }));
  }

  private fillOrdersTime() {
    this.chartOrdersTime?.series.getIndex(0).data.clear();
    this.chartOrdersTime?.series.getIndex(0).data.setAll(this.ordersTime);
  }

  private fillOrdersDayOfWeek() {
    this.chartOrdersDayOfWeek?.series.getIndex(0).data.clear();
    this.chartOrdersDayOfWeek?.series.getIndex(0).data.setAll(this.ordersDayOfWeek);
  }

  private initDayOfWeekMap() {
    this.dayOfWeekMap.set(1, this.tr.translate('day-of-week.short.Monday'));
    this.dayOfWeekMap.set(2, this.tr.translate('day-of-week.short.Tuesday'));
    this.dayOfWeekMap.set(3, this.tr.translate('day-of-week.short.Wednesday'));
    this.dayOfWeekMap.set(4, this.tr.translate('day-of-week.short.Thursday'));
    this.dayOfWeekMap.set(5, this.tr.translate('day-of-week.short.Friday'));
    this.dayOfWeekMap.set(6, this.tr.translate('day-of-week.short.Saturday'));
    this.dayOfWeekMap.set(7, this.tr.translate('day-of-week.short.Sunday'));
  }


  private disposeCharts() {
    this.browserOnly(() => {
      this.chartSale?.dispose();
      this.chartOrdersTime?.dispose();
      this.chartOrdersDayOfWeek?.dispose();
      this.chartSourceType?.dispose();
      this.chartPaymentType?.dispose();
    });
  }
}
